function Projects() {
    return (
        <div className="container">
            <div className="card mt-5 mb-5">
                <div className="card-body">
                    <h5 className="card-title">Minecraft Clone</h5>
                    <h6 className="card-subtitle mb-2 text-muted">C | OpenGL | Git</h6>
                    <p className="card-text">
                        Created a clone of Minecraft from scratch. 
                        For the project, I created a renderer,
                        a linear algebra library, and a noise generator.
                        In my clone, the user can fly around a randomly
                        generated world. This project took me about 11
                        days, and I am interested in adding more
                        functionality to it at some point.
                    </p>
                    <a className="card-link btn btn-primary" href="https://github.com/dominicattal/BadMinecraft" target="_blank" rel="noreferrer">Source</a>
                </div>
            </div>
            <div className="card mb-5">
                <div className="card-body">
                    <h5 className="card-title">Snake</h5>
                    <h6 className="card-subtitle mb-2 text-muted">C | OpenGL | Git</h6>
                    <p className="card-text">
                        Created a fully functional snake game using C and OpenGL.
                        The player can move around by using the WASD keys and restart 
                        by pressing the R key. 
                    </p>
                    <a className="card-link btn btn-primary" href="https://github.com/dominicattal/snake" target="_blank" rel="noreferrer">Source</a>
                </div>
            </div>
            <div className="card mb-5">
                <div className="card-body">
                    <h5 className="card-title">Pirate Adventure</h5>
                    <h6 className="card-subtitle mb-2 text-muted">Python | Pygame | Git</h6>
                    <p className="card-text">
                        Worked in a team of four to create a nautical-themed
                        game about fighting pirates and looting islands for treasure.
                        Our team used Git to share files and maintain
                        the same game version on all of our devices. Our project was for HackNJIT, a hackathon 
                        run by the ACM student chapter at NJIT in conjunction with the Ying Wu College of Computing.
                    </p>
                    <a className="card-link btn btn-primary" href="https://github.com/jorkroc/pirateGame" target="_blank" rel="noreferrer">Source</a>
                    <a className="card-link" href="https://hacknjit.org/" target="_blank" rel="noreferrer">HackNJIT</a>
                </div>
            </div>
            <div className="card mb-5">
                <div className="card-body">
                    <h5 className="card-title">Asteroids</h5>
                    <h6 className="card-subtitle mb-2 text-muted">HTML | Javascript</h6>
                    <p className="card-text">
                        Analyzed the original game's mechanics and successfully
                        reverse-engineered a faithful clone in about 12 hours. For this 
                        project, I created a lightweight physics framework for 
                        entity movement and collisions. My clone features accurate 
                        player acceleration and deceleration, randomly generated asteroids 
                        the player must shoot, a point system, 
                        and aliens that spawn at random intervals that may shoot at the player.
                    </p>
                    <a className="card-link btn btn-primary" href="https://github.com/dominicattal/Asteroids" target="_blank" rel="noreferrer">Source</a>
                    <a className="card-link" href="https://web.njit.edu/~da526/" target="_blank" rel="noreferrer">Play</a>
                </div>
            </div>
        </div>
    )
}

export default Projects;