function Home() {
    return (
        <div className="container">
            <div className="row justify-content-center mt-5 mb-5">
                <div className="col-md-6 mb-3">
                    <div className="row justify-content-center">
                        <img className="img-fluid rounded border border-secondary" src="assets/headshot.jpg" width="350" alt="headshot"></img>
                    </div>
                </div>
                <div className="col-md-6">
                    <p>
                        Hello, my name is Dominic Attalienti, and welcome to my website.
                        I am currently a second-year pursuing a Bachelor's in Computer Science
                        at the New Jersey Institute of Technology.
                    </p>
                    <p>
                        My curiosity for computer science extends beyond the classroom. From coding
                        challenges to personal projects, learning has always been a passion
                        of mine. I am always eager to learn more through diligent research and
                        practice.
                    </p>
                </div>
            </div>
        </div>  
    )
}

export default Home;