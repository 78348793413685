function Contact() {
    return (
        <div>
            <form action="https://api.web3forms.com/submit" method="POST">

                <input type="hidden" name="access_key" value="29f80fd4-4b67-4db1-a111-2e8a92012150"></input>

                <div className="container">
                    <div className="form-row mt-5 justify-content-center">
                        <div className="col-md-3">
                            <input className="form-control" type="text" name="name" placeholder="Name" required></input>
                        </div>
                        <div className="col-md-3 margin-control">
                            <input className="form-control" type="email" name="email" placeholder="Email" required></input>
                        </div>
                    </div>
                    <div className="form-row mt-3 justify-content-center">
                        <div className="col-md-6 h-10">
                            <textarea className="form-control" name="message" placeholder="Message" required></textarea>
                        </div>
                    </div>
                    <div class="h-captcha" data-captcha="true"></div>
                    <div className="form-row">
                        <div className="col-md-12 d-flex justify-content-center">
                            <button className="btn btn-primary mt-3" type="submit">Submit Form</button>
                        </div>
                    </div>
                </div>
            </form>

            <script src="https://web3forms.com/client/script.js" async defer></script>
        </div>
    )
}

export default Contact;