import { Link } from "react-router-dom";
import navbarListener from "../utils/navbarListener";

function Navbar() {
  navbarListener();
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{minHeight: 80}}>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav mx-auto">
          <li className="nav-item">
            <Link className="nav-link" id="nav-home" to="">Home</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" id="nav-projects" to="projects">Projects</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" id="nav-resume" to="resume">Resume</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" id="nav-contact" to="contact">Contact</Link>
          </li>
        </ul>
        <div className="justify-content-center gray">
          <a href="https://www.linkedin.com/in/dominicattal/" target="_blank" rel="noreferrer">
              <img className="img-fluid mr-4" src="assets/linkedin.png" width="20" height="20" alt="linkedin"></img>
          </a>
          <a className="email-button mr-2" href="mailto:da526@njit.edu">
              <img className="img-fluid mr-3" src="assets/email.png" width="20" height="20" alt="email"></img>
          </a>
          <a href="https://github.com/dominicattal" target="_blank" rel="noreferrer">
              <img className="img-fluid mr-5" src="assets/github.png" width="20" height="20" alt="github"></img>
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;