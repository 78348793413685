function NoPage() {
    return (
        <div className="container">
            <div className="row mt-5 justify-content-center">
                <p>You're not supposed to be here!</p>
            </div>
        </div>
    )
}

export default NoPage;