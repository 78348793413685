function Resume() {
    return (
        <div className="container h-100 d-flex flex-column">
            <div className="row mt-5 mb-5 justify-content-center">
                <object data="resume.pdf" type="application/pdf" title="resume" width="600" height="812" frameBorder="0">
                    <p>It appears you don't have a PDF plugin for this browser.
                    You can <a href="resume.pdf">download the PDF file</a>.</p>
                </object>
            </div>
        </div>
    )
}

export default Resume;